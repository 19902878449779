import React from 'react';

import Page from '../organisms/Page';
import { P } from '../atoms/Text';
import { useLangStore } from '../../store';
import Loader from '../molecules/Loader';
import styled from 'styled-components';
import Link from '../atoms/Link';
import { colours } from '../../assets/theme';

const NotFoundPage = (pageData) => {
  const currentLang = useLangStore(store => store.currentLang);
  return (
    <Page data={pageData}>
      <Container>
        <div>
          <Loader loading title="404" inline />;
          <TextP
            css={`
              margin-left: 64px;
            `}
            align="center"
          >
            {currentLang === 'fr' ? 'Page non trouvée' : 'Page not found'}
          </TextP>
          <Link to="/" data-hoverable><TextP colour={colours.blue} align="center">Go home</TextP></Link>
        </div>
      </Container>
    </Page>
  );
};

const Container = styled.div`
  height: calc(100vh - 150px);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextP = styled(P)`
margin: 0
`;

export default NotFoundPage;
